<template>
  <div id="divNewPasswordWrapper">
    <VueForm ref="form">
      <VueText sizeLevel="11" weightLevel="3" class="header" color="grey-40"
        >Yeni Şifre Oluştur</VueText
      >
      <VueText sizeLevel="6" weightLevel="3" class="small-header"
        >Pmaktif'e giriş yaparken kullanacağınız şifrenizi belirleyiniz.</VueText
      >
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40">Yeni Şifre</VueText>
      <VueInput
        :type="passwordType"
        name="password"
        id="password"
        validatorName="Şifre"
        v-model="formModel.password"
        :messageOnTop="true"
        :inputLarge="true"
        ref="password"
        validationRules="required|max:20|min:8|atLeastTwoChar"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          iconName="IconEye"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          :iconColor="this.passwordType === 'password' ? '' : '#79838E'"
          @click="togglePassword"
        ></VueButton>
      </VueInput>
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
        >Yeni Şifreyi Tekrar Giriniz</VueText
      >
      <VueInput
        :type="passwordType"
        name="passwordRepeat"
        id="passwordRepeat"
        validatorName="Şifre"
        v-model="formModel.passwordRepeat"
        :messageOnTop="true"
        :inputLarge="true"
        validationRules="required|max:20|min:8|atLeastTwoChar|confirmed:password"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          iconName="IconEye"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          :iconColor="this.passwordType === 'password' ? '' : '#79838E'"
          @click="togglePassword"
        ></VueButton>
      </VueInput>
      <div slot="footer" class="button-wrapper">
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :disabled="isDisabled"
          @click.prevent="submitPassword"
          >GÖNDER</BrandButton
        >
      </div>
    </VueForm>
    <BrandAuthFormFooter></BrandAuthFormFooter>
  </div>
</template>

<script>
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import { Account } from '@/services/Api/index';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import StorageHelper from '@/utils/storageHelper';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import LoginMixin from '@/mixins/login.js';
export default {
  name: 'NewPassword',
  mixins: [LoginMixin],
  components: {
    VueText,
    BrandAuthFormFooter,
    VueForm,
    VueInput,
    VueButton,
    BrandButton,
  },
  data: () => {
    return {
      isDisabled: true,
      formModel: {
        password: '',
        passwordRepeat: '',
        smsCode: '',
      },
      loginModel: {
        phoneNumber: '',
        password: '',
      },
      currentPhoneNumber: '',
      submitted: false,
      passwordType: 'password',
    };
  },
  computed: {
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
    minValue() {
      return this.formModel.password.length >= 8;
    },
    atLeast() {
      let list = 0;
      if (this.formModel.password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[0-9]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[a-z]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[A-Z]/g)) {
        list++;
      }
      if (list > 1) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.formModel.smsCode = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_SMS_KEY,
    }).get();
    this.currentPhoneNumber = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY,
    }).get();
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (
          this.atLeast &&
          this.minValue &&
          this.formModel.password === this.formModel.passwordRepeat &&
          this.formModel.smsCode !== ''
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
  methods: {
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
    async submitPassword() {
      this.submitted = true;

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Account.setPassword(
          this.formModel.smsCode,
          this.formModel.password,
          this.formModel.passwordRepeat,
        ).then(res => {
          if (res.data.Data) {
            new StorageHelper({
              id: process.env.VUE_APP_STORAGE_CURRENT_SMS_KEY,
            }).remove();

            //login.js mixin method
            this.processLogin({
              phoneNumber: this.currentPhoneNumber,
              password: this.formModel.password,
              firstLogin: true,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: palette-space-level('60');
}

.form-text {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

/deep/ .button-wrapper {
  padding: palette-space-level('40') 0 palette-space-level('20');
  .submit-button {
    border-radius: palette-radius-level('2');
    span {
      color: #fff;
    }
  }
}
.small-header {
  padding-top: palette-space-level('10');
  padding-bottom: palette-space-level('10');
}
</style>
